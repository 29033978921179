define("js2-client/services/panel-actions", ["exports", "ember-collapsible-panel/services/panel-actions"], function (_exports, _panelActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _panelActions.default;
    }
  });
});