define("js2-client/helpers/stat-string-to-int", ["exports", "js-common/helpers/stat-string-to-int"], function (_exports, _statStringToInt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _statStringToInt.default;
    }
  });
  Object.defineProperty(_exports, "nl2br", {
    enumerable: true,
    get: function get() {
      return _statStringToInt.nl2br;
    }
  });
});