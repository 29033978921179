define("js2-client/models/matter", ["exports", "js-common/models/matter", "ember-api-actions"], function (_exports, _matter, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _matter.default.extend({
    getJurorLocationsCount: (0, _emberApiActions.memberAction)({
      path: 'juror_locations_count',
      type: 'get'
    })
  });

  _exports.default = _default;
});