define("js2-client/templates/components/page-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q667Df6J",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"isDots\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[9],[1,[23,\"page\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"a\"],[12,\"href\",[30,[[23,\"url\"]]]],[9],[1,[23,\"page\"],false],[3,\"action\",[[24,0,[]],\"select\"]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-client/templates/components/page-item.hbs"
    }
  });

  _exports.default = _default;
});