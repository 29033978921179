define("js2-client/components/sc-table/quick-search", ["exports", "@smith-carson/ui/components/sc-table/quick-search"], function (_exports, _quickSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _quickSearch.default;
    }
  });
});