define("js2-client/pods/components/sc-reset/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['sc-login'],
    variant: 'reset' // or "activate"

  });

  _exports.default = _default;
});