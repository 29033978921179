define("js2-client/pods/components/app-nav-secondary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PJ1q7kBh",
    "block": "{\"symbols\":[\"Navbar\",\"Nav\",\"@color\",\"&default\"],\"statements\":[[4,\"if\",[[24,0,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"  \"],[5,\"sc-sticky\",[],[[\"@stickTo\"],[\"top\"]],{\"statements\":[[0,\"\\n    \"],[5,\"sc-app-nav-secondary\",[],[[\"@color\",\"@toggleBreakpoint\"],[[24,3,[]],[24,0,[\"collapseOnBreakpoint\"]]]],{\"statements\":[[0,\"\\n      \"],[5,\"sc-container\",[],[[\"@width\"],[\"fluid\"]],{\"statements\":[[0,\"\\n        \"],[6,[24,1,[\"content\"]],[],[[],[]],{\"statements\":[[0,\"\\n          \"],[6,[24,1,[\"nav\"]],[],[[],[]],{\"statements\":[[0,\"\\n            \"],[15,4,[[24,2,[]]]],[0,\"\\n          \"]],\"parameters\":[2]}],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-client/pods/components/app-nav-secondary/template.hbs"
    }
  });

  _exports.default = _default;
});