define("js2-client/pods/auth/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bd2i73MX",
    "block": "{\"symbols\":[\"Layout\"],\"statements\":[[5,\"sc-external-layout\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[24,1,[\"leftPanel\"]],[],[[],[]]],[0,\"\\n  \"],[6,[24,1,[\"rightPanel\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"sc-login\",[],[[\"@model\",\"@year\",\"@successful\",\"@token\",\"@transport\",\"@authenticate\"],[[24,0,[]],[24,0,[\"year\"]],[24,0,[\"successful\"]],[24,0,[\"token\"]],[24,0,[\"transport\"]],[29,\"action\",[[24,0,[]],\"authenticate\"],null]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-client/pods/auth/login/template.hbs"
    }
  });

  _exports.default = _default;
});