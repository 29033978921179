define("js2-client/pods/components/app-nav-secondary/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    session: Ember.inject.service(),

    /**
     * The breakpoint at which the navbar should collapse.
     * uses bootstrap4 breakpoint values
     * @see https://getbootstrap.com/docs/4.1/layout/overview/#responsive-breakpoints
     */
    collapseOnBreakpoint: null
  });

  _exports.default = _default;
});