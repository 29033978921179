define("js2-client/helpers/array-contains-by-id", ["exports", "js-common/helpers/array-contains-by-id"], function (_exports, _arrayContainsById) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _arrayContainsById.default;
    }
  });
  Object.defineProperty(_exports, "arrayContainsById", {
    enumerable: true,
    get: function get() {
      return _arrayContainsById.arrayContainsById;
    }
  });
});