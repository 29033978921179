define("js2-client/pods/auth/activate/route", ["exports", "js-common/mixins/crud/error", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _error, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_error.default, _unauthenticatedRouteMixin.default, {
    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),
    // reset the model in case you return to add another record
    model: function model(params) {
      return Ember.RSVP.hash({
        validated: this.ajax.request("auth/validate_code/".concat(params.code)),
        password: null,
        code: params.code,
        confirm: null,
        subscribe: true
      }).then(function (hash) {
        hash.user = hash.validated.username;
        hash.valid = hash.validated.valid;
        return hash;
      });
    },
    actions: {
      goToReminder: function goToReminder() {
        this.transitionTo('auth.reminder');
      },
      goToLogin: function goToLogin() {
        this.transitionTo('auth.login');
      },
      reset: function reset(model) {
        var _this = this;

        this.ajax.post('auth/reset', {
          data: {
            password: model.password,
            code: model.code,
            confirm: model.confirm,
            subscribe: model.subscribe,
            appname: 'js-portal',
            from_activate: true
          }
        }).then(function () {
          _this.transitionTo('auth.login');

          _this.notify.success('Success!  Your password is now reset.  Please proceed to login as normal.', {
            closeAfter: 15000
          });
        }, function () {
          _this.notify.error('Error!  The code or password are not valid.', {
            closeAfter: 10000
          });
        });
      }
    }
  });

  _exports.default = _default;
});