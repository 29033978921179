define("js2-client/pods/components/matter/subjects/subject-flags/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    // Color codes for different subject statuses
    // This should be in order of less priority to more priority on class names
    classNameBindings: ['hardshipLocation', 'causeLocation', 'isForeperson'],
    isForeperson: Ember.computed('item', function () {
      return this.get('item.foreperson') === '1';
    }),
    hardshipLocation: Ember.computed('item', function () {
      return this.get('item.location') === 'hardship';
    }),
    causeLocation: Ember.computed('item', function () {
      return this.get('item.location') === 'cause';
    })
  });

  _exports.default = _default;
});