define("js2-client/pods/components/app-nav-primary/component", ["exports", "js-common/mixins/crud/error"], function (_exports, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_error.default, {
    tagName: '',
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),

    /**
     * The breakpoint at which the navbar should collapse.
     * uses bootstrap4 breakpoint values
     * @see https://getbootstrap.com/docs/4.1/layout/overview/#responsive-breakpoints
     */
    collapseOnBreakpoint: 'sm',
    enableSMS: false,
    phone: '+1 ',
    sendOTP: false,
    otpCode: null,
    actions: {
      logout: function logout() {
        try {
          this.session.invalidate();
        } catch (e) {
          this.transitionTo('auth.login');
        }
      },
      validate: function validate() {
        var _this = this;

        if (!this.sendOTP) {
          this.ajax.post('auth/validate-phone', {
            data: {
              phone: this.phone,
              token: this.get('session.data.authenticated.token')
            }
          }).then(function (response) {
            if (response.otp_sent) {
              _this.set('sendOTP', true);
            } else {
              _this.notify.error("Sorry we can't deliver the SMS");
            }
          }).catch(function (error) {
            _this.handleXHR(error);
          });
        } else {
          // Verify code
          this.ajax.post('auth/validate-sms-code', {
            data: {
              otp_code: this.otpCode,
              phone: this.phone,
              token: this.get('session.data.authenticated.token')
            }
          }).then(function (response) {
            if (response.saved) {
              _this.set('enableSMS', false);

              _this.session.invalidate();
            } else {
              _this.notify.error('The code is invalid');
            }
          }).catch(function (error) {
            _this.handleXHR(error);
          });
        }
      }
    }
  });

  _exports.default = _default;
});