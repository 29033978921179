define("js2-client/services/popover-singleton", ["exports", "@smith-carson/ui/services/popover-singleton"], function (_exports, _popoverSingleton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _popoverSingleton.default;
    }
  });
});