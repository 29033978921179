define("js2-client/services/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    /**
     * verify that the service is accessible and that the store has been injected
     */
    logMe: function logMe() {
      var store = this.store;
      store.findAll('employee');
    },
    fileTypes: [{
      label: 'BANK',
      value: 'BANK',
      order: '1'
    }, {
      label: 'BIRTH',
      value: 'BIRTH',
      order: '2'
    }, {
      label: 'CIVIL',
      value: 'CIVIL',
      order: '3'
    }, {
      label: 'CLIENT',
      value: 'CLIENT',
      order: '4'
    }, {
      label: 'COMP REPT',
      value: 'COMP REPT',
      order: '5'
    }, {
      label: 'CRIM',
      value: 'CRIM',
      order: '6'
    }, {
      label: 'DEATH',
      value: 'DEATH',
      order: '7'
    }, {
      label: 'EDU',
      value: 'EDU',
      order: '8'
    }, {
      label: 'EMPL/BUS',
      value: 'EMPL/BUS',
      order: '9'
    }, {
      label: 'MARITAL/DOMESTIC',
      value: 'MARITAL/DOMESTIC',
      order: '10'
    }, {
      label: 'MEDICAL',
      value: 'MEDICAL',
      order: '11'
    }, {
      label: 'NEWS',
      value: 'NEWS',
      order: '12'
    }, {
      label: 'OTHER/MISC',
      value: 'OTHER/MISC',
      order: '14'
    }, {
      label: 'PHOTO',
      value: 'PHOTO',
      order: '15'
    }, {
      label: 'PROP',
      value: 'PROP',
      order: '16'
    }, {
      label: 'SCRAPE',
      value: 'SCRAPE',
      order: '17'
    }, {
      label: 'TRAFFIC',
      value: 'TRAFFIC',
      order: '19'
    }, {
      label: 'UNCLASSIFIED',
      value: 'UNCLASSIFIED',
      order: '20'
    }, {
      label: 'WEB/SOCNET',
      value: 'WEB/SOCNET',
      order: '21'
    }, {
      label: 'PROFILE PHOTO',
      value: 'PROFILE PHOTO',
      order: '22'
    }],
    boolean: [{
      name: 'True',
      value: 1
    }, {
      name: 'False',
      value: 0
    }],
    contactUsTypes: [{
      value: 'general',
      display: 'General Support',
      order: 1,
      email: 'support@smithcarson.com'
      /*,
      {
      value: 'tech',
      display: 'Technical Support',
      order: 2,
      email: 'bugs@smithcarson.com'
      } */

    }]
  });

  _exports.default = _default;
});