define("js2-client/components/multiselect-checkboxes", ["exports", "ember-multiselect-checkboxes/components/multiselect-checkboxes"], function (_exports, _multiselectCheckboxes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _multiselectCheckboxes.default;
    }
  });
});