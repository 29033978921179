define("js2-client/initializers/auth-token", ["exports", "js-common/initializers/auth-token"], function (_exports, _authToken) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _authToken.default;
    }
  });
});