define("js2-client/models/resource-has-resource", ["exports", "js-common/models/resource-has-resource"], function (_exports, _resourceHasResource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resourceHasResource.default.extend({});

  _exports.default = _default;
});