define("js2-client/pods/auth/reminder/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ho0MT5g8",
    "block": "{\"symbols\":[\"Layout\"],\"statements\":[[5,\"sc-external-layout\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[24,1,[\"leftPanel\"]],[],[[],[]]],[0,\"\\n  \"],[6,[24,1,[\"rightPanel\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"sc-reminder\",[],[[\"@year\",\"@model\",\"@reminder\",\"@goToLogin\",\"@goToOtpRequest\"],[[24,0,[\"year\"]],[24,0,[\"model\"]],[24,0,[\"reminder\"]],[29,\"route-action\",[\"goToLogin\"],null],[29,\"route-action\",[\"goToOtpRequest\"],null]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-client/pods/auth/reminder/template.hbs"
    }
  });

  _exports.default = _default;
});