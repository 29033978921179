define("js2-client/helpers/trunc-text-by-char", ["exports", "js-common/helpers/trunc-text-by-char"], function (_exports, _truncTextByChar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _truncTextByChar.default;
    }
  });
  Object.defineProperty(_exports, "truncTextByChar", {
    enumerable: true,
    get: function get() {
      return _truncTextByChar.truncTextByChar;
    }
  });
});