define("js2-client/helpers/remove-links", ["exports", "js-common/helpers/remove-links"], function (_exports, _removeLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _removeLinks.default;
    }
  });
  Object.defineProperty(_exports, "removeLinks", {
    enumerable: true,
    get: function get() {
      return _removeLinks.removeLinks;
    }
  });
});