define("js2-client/components/one-way-week", ["exports", "ember-one-way-controls/components/one-way-week"], function (_exports, _oneWayWeek) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _oneWayWeek.default;
    }
  });
});