define("js2-client/helpers/user-avatar-url", ["exports", "js-common/helpers/user-avatar-url"], function (_exports, _userAvatarUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _userAvatarUrl.default;
    }
  });
  Object.defineProperty(_exports, "subjectAvatarUrl", {
    enumerable: true,
    get: function get() {
      return _userAvatarUrl.subjectAvatarUrl;
    }
  });
});