define("js2-client/components/ember-chart", ["exports", "ember-cli-chartjs/components/ember-chart"], function (_exports, _emberChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _emberChart.default;
    }
  });
});