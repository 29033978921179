define("js2-client/models/matter-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    prefix: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    active: _emberData.default.attr('number'),
    tasking: _emberData.default.attr('string'),
    // not on the backend, only for show on the UI
    numberOfTaskTemplates: _emberData.default.attr('number')
  });

  _exports.default = _default;
});