define("js2-client/helpers/max-date", ["exports", "js-common/helpers/max-date"], function (_exports, _maxDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _maxDate.default;
    }
  });
  Object.defineProperty(_exports, "maxDate", {
    enumerable: true,
    get: function get() {
      return _maxDate.maxDate;
    }
  });
});