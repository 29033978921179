define("js2-client/pods/auth/otp-login/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    year: Ember.computed(function () {
      return new Date().getFullYear();
    }).volatile(),
    isProcessing: false
  });

  _exports.default = _default;
});