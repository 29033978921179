define("js2-client/models/list-template-group", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    matterType: _emberData.default.belongsTo('matter-type', {
      async: true
    }),
    listTemplates: _emberData.default.hasMany('list-template', {
      async: true
    }),
    matterTypeWritable: Ember.computed('matterType', {
      get: function get(key) {
        return this.get('matterType.content');
      },
      set: function set(key, value) {
        this.set('matterType', value);
        return value;
      }
    })
  });

  _exports.default = _default;
});