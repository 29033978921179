define("js2-client/initializers/controller-lifecycle", ["exports", "ember-controller-lifecycle/initializers/controller-lifecycle"], function (_exports, _controllerLifecycle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _controllerLifecycle.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _controllerLifecycle.initialize;
    }
  });
});