define("js2-client/helpers/set-var", ["exports", "js-common/helpers/set-var"], function (_exports, _setVar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _setVar.default;
    }
  });
});