define("js2-client/utils/doc-name-parsing", ["exports", "js-common/utils/doc-name-parsing"], function (_exports, _docNameParsing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _docNameParsing.default;
    }
  });
});