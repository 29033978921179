define("js2-client/initializers/add-authorized-mixin", ["exports", "js-common/mixins/authorized-route-mixin"], function (_exports, _authorizedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    Ember.Route.reopen(_authorizedRouteMixin.default, {
      /*
      By default all routes now need permissions
      For Available options:
      Select * From permissions
      */
      requiredPermissions: [],
      requireAnyPermission: []
    });
  }

  var _default = {
    name: 'add-authorized-mixin',
    initialize: initialize
  };
  _exports.default = _default;
});