define("js2-client/components/modals/subjects-filter", ["exports", "@smith-carson/ui/components/modals/subjects-filter"], function (_exports, _subjectsFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _subjectsFilter.default;
    }
  });
});