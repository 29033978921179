define("js2-client/models/budget", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    prevAmount: _emberData.default.attr('number'),
    notes: _emberData.default.attr('string'),
    enabled: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    hours: _emberData.default.attr('number'),
    // relationships
    matter: _emberData.default.belongsTo('matter', {
      async: false
    }),
    billables: _emberData.default.hasMany('billable', {
      async: false
    }),
    isLinkedToBillable: Ember.computed('billables.length', function () {
      return this.get('billables.length') > 0;
    })
  });

  _exports.default = _default;
});