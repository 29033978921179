define("js2-client/models/v_matter_task", ["exports", "jquery", "ember-data"], function (_exports, _jquery, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    status: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    priority: _emberData.default.attr('string'),
    timeLimit: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    // relationships
    assignedTo: _emberData.default.belongsTo('employee', {
      async: true
    }),
    workflow: _emberData.default.belongsTo('workflow', {
      async: true
    }),
    matterHasWorkflow: _emberData.default.belongsTo('matter-has-workflow', {
      async: true
    }),
    matter: _emberData.default.belongsTo('matter'),
    task: _emberData.default.belongsTo('task', {
      async: true
    }),
    fullName: Ember.computed('firstName', 'lastName', function () {
      var fullName = '';

      if (Ember.isPresent(this.lastName)) {
        fullName += this.lastName;
      }

      if (Ember.isPresent(this.firstName)) {
        fullName += ', ' + this.firstName;
      }

      return (0, _jquery.default)('<div/>').html(fullName).text();
    })
  });

  _exports.default = _default;
});