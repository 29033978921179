define("js2-client/pods/components/sc-light-table/cells/review/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6gLx92fk",
    "block": "{\"symbols\":[\"@row\",\"@tableActions\"],\"statements\":[[4,\"if\",[[24,0,[\"hasReviewedResource\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"review-icon\"],[12,\"id\",[29,\"concat\",[\"hover-reviewed-\",[24,1,[\"id\"]]],null]],[11,\"role\",\"button\"],[9],[0,\"\\n    \"],[5,\"sc-icon\",[],[[\"@icon\",\"@color\"],[\"check\",\"jade\"]]],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],[29,\"optional\",[[24,2,[\"toggleReviewedByMe\"]]],null],[24,1,[\"content\"]]]],[10],[0,\"\\n  \"],[5,\"sc-popover\",[],[[\"@triggerElement\",\"@triggerEvents\"],[[29,\"concat\",[\"#hover-reviewed-\",[24,1,[\"id\"]]],null],\"hover\"]],{\"statements\":[[0,\"\\n    Reviewed\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"not\",[[25,[\"value\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"review-icon\"],[12,\"id\",[29,\"concat\",[\"hover-unreviewed-\",[24,1,[\"id\"]]],null]],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"role\",\"button\"],[9],[0,\"\\n      \"],[5,\"sc-icon\",[],[[\"@icon\",\"@color\"],[\"check\",\"primary\"]]],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],[29,\"optional\",[[24,2,[\"toggleReviewedByMe\"]]],null],[24,1,[\"content\"]]]],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[5,\"sc-popover\",[],[[\"@triggerElement\",\"@triggerEvents\"],[[29,\"concat\",[\"#hover-unreviewed-\",[24,1,[\"id\"]]],null],\"hover\"]],{\"statements\":[[0,\"\\n    Mark as reviewed\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-client/pods/components/sc-light-table/cells/review/template.hbs"
    }
  });

  _exports.default = _default;
});