define("js2-client/models/firm-has-client", ["exports", "js-common/models/firm-has-client"], function (_exports, _firmHasClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _firmHasClient.default.extend({});

  _exports.default = _default;
});