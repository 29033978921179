define("js2-client/components/textarea-autosize", ["exports", "ember-cli-textarea-autosize/components/textarea-autosize"], function (_exports, _textareaAutosize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _textareaAutosize.default;
    }
  });
});