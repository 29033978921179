define("js2-client/components/sc-table/footer", ["exports", "@smith-carson/ui/components/sc-table/footer"], function (_exports, _footer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _footer.default;
    }
  });
});