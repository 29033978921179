define("js2-client/helpers/string-contains", ["exports", "js-common/helpers/string-contains"], function (_exports, _stringContains) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _stringContains.default;
    }
  });
  Object.defineProperty(_exports, "stringContains", {
    enumerable: true,
    get: function get() {
      return _stringContains.stringContains;
    }
  });
});