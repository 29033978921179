define("js2-client/pods/auth/reset/route", ["exports", "js-common/mixins/crud/error", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _error, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_error.default, _unauthenticatedRouteMixin.default, {
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    // reset the model in case you return to add another record
    model: function model(params) {
      return Ember.RSVP.hash({
        valid: this.ajax.request("auth/validate_code/".concat(params.code)).then(function (response) {
          return response.valid;
        }),
        password: null,
        code: params.code,
        confirm: null
      });
    },
    actions: {
      goToReminder: function goToReminder() {
        this.transitionTo('auth.reminder');
      },
      goToLogin: function goToLogin() {
        this.transitionTo('auth.login');
      },
      reset: function reset(model) {
        var _this = this;

        this.ajax.post('auth/reset', {
          data: model
        }).then(function () {
          _this.notify.success('Success! Your password is now reset. Please proceed to login as normal.', {
            closeAfter: 15000
          });

          _this.transitionTo('auth.login');
        }, function () {
          _this.notify.error('Error! The code or password are not valid.', {
            closeAfter: 10000
          });
        });
      }
    }
  });

  _exports.default = _default;
});