define("js2-client/pods/components/monitoring-activity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9IVLxjnz",
    "block": "{\"symbols\":[\"note\"],\"statements\":[[4,\"each\",[[25,[\"notes\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"mb-3\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"d-flex\"],[9],[0,\"\\n      \"],[7,\"div\"],[9],[0,\"\\n        \"],[5,\"sc-badge\",[[13,\"class\",\"mb-1\"]],[[\"@color\",\"@size\"],[\"tiger\",\"sm\"]],{\"statements\":[[0,\"\\n          \"],[1,[29,\"moment-format\",[[24,1,[\"createdOn\"]],\"dddd, MMMM DD, YYYY @ h:mm a\"],null],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[10],[0,\"\\n\"],[4,\"if\",[[24,1,[\"hasDocuments\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"ml-auto mt-n2\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"gt\",[[24,1,[\"documentCount\"]],1],null]],null,{\"statements\":[[0,\"            \"],[5,\"sc-icon-button\",[],[[\"@icon\",\"@color\",\"@onClick\"],[\"attach\",\"primary\",[29,\"action\",[[24,0,[]],\"downloadAttachments\",[24,1,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[5,\"document-download-link\",[[13,\"class\",\"btn btn-link\"]],[[\"@id\",\"@icon\"],[[24,1,[\"documentId\"]],\"paperclip\"]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n    \"],[1,[24,1,[\"body\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[5,\"sc-alert\",[],[[\"@type\",\"@dismissible\"],[\"info\",false]],{\"statements\":[[0,\"\\n    No monitoring activity\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-client/pods/components/monitoring-activity/template.hbs"
    }
  });

  _exports.default = _default;
});