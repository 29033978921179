define("js2-client/pods/auth/otp-login/route", ["exports", "js-common/mixins/crud/error", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _error, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_error.default, _unauthenticatedRouteMixin.default, {
    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    queryParams: {
      email: {
        refreshModel: true
      },
      passcode: {
        refreshModel: true
      }
    },
    // reset the model in case you return to add another record
    model: function model(params) {
      return {
        email: params.email,
        code: params.passcode,
        urlCode: params.code,
        app: 'js-portal'
      };
    },
    actions: {
      goToLogin: function goToLogin() {
        this.transitionTo('auth.login');
      },
      loginOtp: function loginOtp(model) {
        var _this = this;

        if (this.get('isProcessing')) {
          return;
        }

        this.set('isProcessing', true);
        this.ajax.post('auth/otp/login', {
          data: model
        }).then(function (results) {
          _this.get('notify').success('Login Successful!');

          _this.set('isProcessing', false);

          window.location.assign(_this.get('router').urlFor('matters', {
            queryParams: {
              newtoken: results.token
            }
          }));
        }).catch(function (response) {
          if (response.payload) {
            if (response.payload.errors) {
              response.payload.errors.forEach(function (error) {
                _this.get('notify').error(error.additional_info.title);
              });
            }
          } else {
            _this.get('notify').error(response);
          }

          _this.set('isProcessing', false);
        });
      }
    }
  });

  _exports.default = _default;
});