define("js2-client/pods/trial-data/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      cn: {
        refreshModel: true
      },
      pl: {
        refreshModel: true
      },
      df: {
        refreshModel: true
      },
      vs: {
        refreshModel: true
      },
      vc: {
        refreshModel: true
      },
      tf: {
        refreshModel: true
      },
      tt: {
        refreshModel: true
      },
      cf: {
        refreshModel: true
      },
      ct: {
        refreshModel: true
      },
      pf: {
        refreshModel: true
      },
      pt: {
        refreshModel: true
      },
      pff: {
        refreshModel: true
      },
      pft: {
        refreshModel: true
      },
      dff: {
        refreshModel: true
      },
      dft: {
        refreshModel: true
      },
      tg: {
        refreshModel: true
      },
      cs: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      perPage: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      qf: {
        refreshModel: true
      },
      sortField: {
        refreshModel: true
      },
      rf: {
        refreshModel: true
      }
    },
    // eslint-disable-next-line complexity
    model: function model(params) {
      var casesQuery = {
        progress_status: 'Complete'
      };

      if (params.cn) {
        casesQuery['person_of_interest||name'] = "*".concat(params.cn, "*");
      }

      if (params.pl) {
        casesQuery.plaintiff = "*".concat(params.pl, "*");
      }

      if (params.df) {
        casesQuery.defendant = "*".concat(params.df, "*");
      }

      if (params.vs) {
        casesQuery.venue_state = "*".concat(params.vs, "*");
      }

      if (params.vc) {
        casesQuery.venue_county = "*".concat(params.vc, "*");
      }

      if (params.tf) {
        casesQuery.start_date = ">=".concat(params.tf);
      }

      if (params.tt) {
        casesQuery.start_date = "<=".concat(params.tt);
      }

      if (params.tf && params.tt) {
        casesQuery.start_date = "~".concat(params.tf, "~").concat(params.tt);
      }

      if (params.cf) {
        casesQuery.compensatory = ">=".concat(params.cf);
      }

      if (params.ct) {
        casesQuery.compensatory = "<=".concat(params.ct);
      }

      if (params.cf && params.ct) {
        casesQuery.compensatory = "~".concat(params.cf, "~").concat(params.ct);
      }

      if (params.pf) {
        casesQuery.punitive = ">=".concat(params.pf);
      }

      if (params.pt) {
        casesQuery.punitive = "<=".concat(params.pt);
      }

      if (params.pf && params.pt) {
        casesQuery.punitive = "~".concat(params.pf, "~").concat(params.pt);
      }

      if (params.pff) {
        casesQuery.plaintiff_fault = ">=".concat(params.pff);
      }

      if (params.pft) {
        casesQuery.plaintiff_fault = "<=".concat(params.pft);
      }

      if (params.pff && params.pft) {
        casesQuery.plaintiff_fault = "~".concat(params.pff, "~").concat(params.pft);
      }

      if (params.dff) {
        casesQuery.defendant_fault = ">=".concat(params.dff);
      }

      if (params.dft) {
        casesQuery.defendant_fault = "<=".concat(params.dft);
      }

      if (params.dff && params.dft) {
        casesQuery.defendant_fault = "~".concat(params.dff, "~").concat(params.dft);
      }

      if (params.cs) {
        casesQuery.case_status = "".concat(params.cs);
      }

      if (params.page) {
        casesQuery.page = params.page;
      }

      if (params.perPage) {
        casesQuery.perPage = params.perPage;
      }

      if (params.q) {
        var fields = '';

        switch (params.qf) {
          case 'All':
            fields = 'name||person_of_interest||plaintiff||defendant';
            break;

          case 'Plaintiff':
            fields = 'plaintiff';
            break;

          case 'Defendant':
            fields = 'defendant';
            break;

          default:
        }

        casesQuery[fields] = "*".concat(params.q, "*");
      }

      if (params.sortField) {
        casesQuery.sortField = params.sortField;
      }

      if (params.tg) {
        casesQuery.calc_public_tags = "*".concat(params.tg, "*");
      }

      return Ember.RSVP.hash({
        cases: this.store.query('matter', casesQuery),
        params: params,
        casesQuery: casesQuery
      });
    },
    setupController: function setupController(controller, model) {
      var cases = model.cases,
          params = model.params,
          casesQuery = model.casesQuery;
      var totalRecords = cases.meta.total_record_count;
      controller.setProperties({
        cases: cases,
        caseSearchCategory: Ember.isPresent(params.qf) ? params.qf : 'All',
        searchTerms: params.q,
        totalRecords: totalRecords,
        casesQuery: casesQuery
      }); // Do not render the table until the data is loaded

      Ember.run.schedule('afterRender', function () {
        controller.set('isLoading', false);
      });
    },
    actions: {
      /**
          Updates the URL to include the relevant query parameter values when
          they are set programmatically.
           @method didTransition
          @returns {Boolean}
       */
      didTransition: function didTransition() {
        var _this = this;

        Ember.run.schedule('afterRender', function () {
          window.history.replaceState({}, '', // eslint-disable-next-line ember/no-controller-access-in-routes
          "/trial-data?".concat(_this.controller.buildQueryParamsString()));
        });
        return true;
      },
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});