define("js2-client/utils/sc-table/column", ["exports", "@smith-carson/ui/utils/sc-table/column"], function (_exports, _column) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _column.default;
    }
  });
});