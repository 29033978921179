define("js2-client/serializers/matter", ["exports", "active-model-adapter"], function (_exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    normalize: function normalize(typeClass, hash, prop) {
      // https://github.com/emberjs/rfcs/pull/57#issuecomment-121008369
      var foreignKey = hash['matter_type_id'];

      if (foreignKey) {
        hash['matter_type_id'] = foreignKey;
      }

      return this._super(typeClass, hash, prop);
    },
    // http://emberjs.com/blog/2015/06/18/ember-data-1-13-released.html#toc_upgrade-guide
    isNewSerializerAPI: true
  });

  _exports.default = _default;
});