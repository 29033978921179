define("js2-client/pods/components/sc-external-layout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aIFUp8YE",
    "block": "{\"symbols\":[\"Row\",\"Col\",\"&default\"],\"statements\":[[5,\"sc-container\",[],[[\"@width\"],[\"full\"]],{\"statements\":[[0,\"\\n  \"],[6,[24,1,[]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[15,3,[[29,\"hash\",null,[[\"leftPanel\",\"rightPanel\"],[[29,\"component\",[[24,0,[\"leftPanelComponent\"]]],[[\"col\"],[[24,2,[]]]]],[29,\"component\",[[24,0,[\"rightPanelComponent\"]]],[[\"col\"],[[24,2,[]]]]]]]]]],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-client/pods/components/sc-external-layout/template.hbs"
    }
  });

  _exports.default = _default;
});