define("js2-client/controllers/application", ["exports", "js-common/mixins/token-controller"], function (_exports, _tokenController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_tokenController.default, {});

  _exports.default = _default;
});