define("js2-client/helpers/user-has-reviewed-subject", ["exports", "js-common/helpers/user-has-reviewed-subject"], function (_exports, _userHasReviewedSubject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _userHasReviewedSubject.default;
    }
  });
  Object.defineProperty(_exports, "userHasReviewedSubject", {
    enumerable: true,
    get: function get() {
      return _userHasReviewedSubject.userHasReviewedSubject;
    }
  });
});