define("js2-client/models/unauth-document-download", ["exports", "js-common/models/document-download"], function (_exports, _documentDownload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _documentDownload.default.extend({});

  _exports.default = _default;
});