define("js2-client/pods/auth/force-login/route", ["exports", "js-common/mixins/open-route-mixin"], function (_exports, _openRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_openRouteMixin.default, {
    session: Ember.inject.service(),
    queryParams: {
      token: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var token = params.token;
      var self = this;
      return this.session.authenticate('authenticator:token', token).then(function () {
        self.transitionTo('matters');
      }, function () {
        self.transitionTo('auth.login');
      });
    }
  });

  _exports.default = _default;
});